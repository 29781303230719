@import url(//fonts.googleapis.com/earlyaccess/thabit.css);

@import url(https://rsms.me/inter/inter.css);

.letter-image {
  position: relative;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.animated-mail .body {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 200px;
  border-color: transparent transparent #e95f55 transparent;
  z-index: 2;
}
.animated-mail .top-fold {
  position: absolute;
  top: 50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100px 0 100px;
  -webkit-transform-origin: 50% 0%;
  -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  -moz-transform-origin: 50% 0%;
  -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  transform-origin: 50% 0%;
  transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  border-color: #cf4a43 transparent transparent transparent;
  z-index: 2;
}
.animated-mail .back-fold {
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 100px;
  background: #cf4a43;
  z-index: 0;
}
.animated-mail .left-fold {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-color: transparent transparent transparent #e15349;
  z-index: 2;
}
.animated-mail .letter {
  left: 20px;
  bottom: 0px;
  position: absolute;
  width: 160px;
  height: 60px;
  /* background: white; */
  background: linear-gradient(
    225deg,
    #ffbdbd 5.97%,
    #f64b4b 40.72%,
    #ff0000 79.04%
  );

  z-index: 1;
  overflow: hidden;
  -webkit-transition: 0.4s 0.2s;
  -moz-transition: 0.4s 0.2s;
  transition: 0.4s 0.2s;
}
.animated-mail .letter .letter-border {
  height: 10px;
  width: 100%;
  background: repeating-linear-gradient(
    -45deg,
    #cb5a5e,
    #cb5a5e 8px,
    transparent 8px,
    transparent 18px
  );
}
.animated-mail .letter .letter-title {
  margin-top: 10px;
  margin-left: 5px;
  height: 10px;
  width: 40%;
  background: #cb5a5e;
}
.animated-mail .letter .letter-context {
  margin-top: 10px;
  margin-left: 5px;
  height: 10px;
  width: 20%;
  background: #cb5a5e;
}
.animated-mail .letter .letter-context-body {
  margin-top: 10px;
  margin-left: 5px;
  /* height: 10px; */
  width: 99%;
  color: black;
  content: "ram avtar";
}
.animated-mail .letter .letter-stamp {
  margin-top: 30px;
  margin-left: 120px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #cb5a5e;
  opacity: 0.3;
}

.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);
  border-radius: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.letter-image:hover .animated-mail {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
}
.letter-image:hover .animated-mail .top-fold {
  transition: transform 0.4s, z-index 0.2s;
  transform: rotateX(180deg);
  -webkit-transition: transform 0.4s, z-index 0.2s;
  -webkit-transform: rotateX(180deg);
  -moz-transition: transform 0.4s, z-index 0.2s;
  -moz-transform: rotateX(180deg);
  z-index: 0;
}
.letter-image:hover .animated-mail .letter {
  height: 280px;
  content: "ram avtar";
  color: #000;
}
.letter-image:hover .shadow {
  width: 250px;
}

.logo-stamp {
  width: 50px;
  height: 50px;
  background: url("../../assets/dragonmailLogo.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.envelope-logo {
  background: linear-gradient(
    87.02deg,
    #ffbcbc 2.21%,
    #f85656 49.38%,
    #ff0101 97.53%
  );
  width: 235px;
  height: 235px;
  background-image: url("../../assets/dragonmailLogo.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.envelope-logo-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 0;
}

.account-btn-grad {
  background-color: #ff0101;
  /* box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25); */
  margin: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  display: inline-block;
  z-index: 1;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}
/*  */
.token-btn-grad {
  background-color: #e5645e;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  top: 50px;
  right: 50px;
  padding-left: 55px;
  padding-right: 140px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  display: block;
  position: absolute;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}

.dragonmail-glass-btn-grad {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  margin: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  background-size: 300% auto;
  border: 0.2px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;
  display: inline-block;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}

.dragonmail-glass-btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.desktopAccountButtonContainer {
  display: flex;
  position: absolute;
  top: 40;
  right: 40;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.desktopSpreadButtonContainer {
  display: flex;
  position: fixed;
  bottom: 50;
  left: 0%;
  right: 0%;
  width: fit-content;
  block-size: fit-content;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

accountButtonContainer {
  background: #ff0101;
}

.envelopeBackgroundContainer {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 15px;
  width: 80%;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto; /* Add this line to enable horizontal scrolling */
}

.envelopeContainer {
  display: block;
  width: 133px;
  height: 225px;
  min-width: 133px;
  margin: 0;
  margin-bottom: 5px;
  margin-right: 40px;
  margin-left: 40px;
  overflow: visible;
}

.envelope {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/envelopeClose1.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.senderName {
  display: block;
  position: absolute;
  bottom: 14px;
  margin-left: 0px;
  width: 133px;
  height: 30px;
  background-color: #460109;
  border-radius: 35px;
  text-align: center;
  z-index: 99;
}

.dot-border {
  display: none;
  position: absolute;
  background-color: #D1A251;
  color: white;
  top: 0;
  margin-left: 70px;
  cursor: pointer;
  width: 75px;
  height: 30px;
  border-radius: 100;
}

/* .envelopeBackgroundContainer:hover .envelopeContainer {
  filter: blur(10px);
  width: 25%;
}

.envelopeBackgroundContainer .envelopeContainer:hover {
  filter: blur(0px);
} */

.top-fold {
  display: none;
  position: absolute;
  width: 133px;
  height: 133px;
  background: url("../../assets/envelopeFold.png");
  background-size: cover;
  text-align: center;
  z-index: 99;
}

/* .envelopeBackgroundContainer .envelopeContainer:hover .top-fold {
  margin-left: 20px;
  top: -60px;
  left: 0;
  display: block;
} */

.letter-bottom {
  display: none;
  position: absolute;
  width: 115px;
  height: 65px;
  background: url("../../assets/letterBottom.png");
  background-size: cover;
  z-index: 100;
}
/* 
.envelopeBackgroundContainer .envelopeContainer:hover .letter-bottom {
  margin-left: 33px;
  top: 8px;
  left: 0;
  display: block;
} */

.letter-top {
  display: none;
  position: absolute;
  width: 107px;
  height: 30px;
  background: url("../../assets/letterTop.png");
  background-size: cover;
  z-index: 100;
}

/* .envelopeBackgroundContainer .envelopeContainer:hover .letter-top {
  margin-left: 33px;
  top: -8px;
  left: 0;
  display: block;
} */

/* Add these styles for customizing the scrollbar */
.envelopeBackgroundContainer::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

.envelopeBackgroundContainer::-webkit-scrollbar-thumb {
  background-color: #ff0101; /* Set the color of the scrollbar thumb */
  border: 0.2px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px; /* Set the border radius of the thumb */
}

.envelopeBackgroundContainer::-webkit-scrollbar-track {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Set the color of the scrollbar track */
  border-radius: 6px; /* Set the border radius of the thumb */
}

/* Optional: Style the scrollbar on hover */
.envelopeBackgroundContainer:hover::-webkit-scrollbar-thumb {
  background-color: #ff3333; /* Change color on hover */
}

.buttonBox {
  width: 280px;
  margin: 0px auto;
  margin-bottom: 20px;
  position: relative;
  border-radius: 30px;
  background: #eb3323;
}

.toggleBtn {
  padding: 10px 52px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
  color: #fff;

  font-family: "Inter";
  font-style: bold;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
}

.H11 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  overflow-wrap: break-word;
}

.H12 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #DBA95C;
  overflow-wrap: break-word;
}

.H13 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #900001;
  overflow-wrap: break-word;
}

.H14 {
  margin-top: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  overflow-wrap: break-word;
}

#btn {
  left: 0;
  top: 0;
  position: absolute;
  width: 140px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  transition: 0.5s;
}

.new-letter {
  display: block;
  position: absolute;
  left: 50%;
  top: 37%;
  width: 180px;
  height: 180px;
  padding-top: 12px;
  background-color: #d9d9d9;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: slideIn .8s linear;
  z-index: 997;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

@keyframes slideIn {
  0% {
    transform: translate(-50%, -0%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(-50%, -50%);
    animation-timing-function: ease-in;
  }
}

.big-envelope-open {
  display: block;
  position: absolute;
  background-image: url("../../assets/envelopeOpen.png");
  background-size: contain;
  background-repeat: no-repeat;
  left: 50%;
  top: 52.5%;
  width: 201px;
  height: 337px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 998;
}

.big-envelope-open-half {
  display: block;
  position: absolute;
  background-image: url("../../assets/envelopeOpenHalf.png");
  background-size: contain;
  background-repeat: no-repeat;
  left: 50%;
  top: 64%;
  width: 200px;
  height: 337px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
}

.big-envelope-fold {
  display: block;
  position: absolute;
  background-image: url("../../assets/envelopeFold.png");
  background-size: contain;
  background-repeat: no-repeat;
  left: 50%;
  top: 30.5%;
  width: 202px;
  height: 202px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 996;
}

.big-sender-name {
  display: block;
  position: absolute;
  width: 200px;
  height: 50px;
  left: 50%;
  top: 73%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #460109;
  border-radius: 35px;
  text-align: center;
  z-index: 1001;
}

.claim-btn-grad {
  background-color: #e6941c;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
  top: 37.5%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  background-size: 200% auto;
  color: #dc0305;
  border-radius: 40px;
  display: block;
  position: absolute;
  animation: claimIn .8s linear;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  z-index: 998;
}

@keyframes claimIn {
  0% {
    transform: translate(-50%, 330%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(-50%, -50%);
    animation-timing-function: ease-in;
  }
}

.close-btn-grad {
  background-color: #ff0101;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
  bottom: 19.5%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  background-size: 200% auto;
  color: #ffffff;
  border-radius: 40px;
  display: none;
  position: absolute;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}

.share-btn-grad {
  background-color: #ff0101;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
  right: 25%;
  left: 25%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  transition: 0.5s;
  background-size: 200% auto;
  color: #ffffff;
  border-radius: 40px;
  display: block;
  position: relative;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  z-index: 100;
}

.blur-background {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(3px);
  top: 0%;
  height: 100vh;
  width: 100%;
  z-index: 995;
}



/* Loader 3 */
.loader-3 {
  display: block;
	height: 32px;
	width: 32px;
}
.loader-3 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader-3 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 3px solid #FFF;
	border-bottom: 3px solid transparent;
	border-radius: 50%;
	-webkit-animation: loader-3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-3-1 {
	0%   { -webkit-transform: rotate(0deg); }
	40%  { -webkit-transform: rotate(180deg); }
	60%  { -webkit-transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-3-1 {
	0%   { transform: rotate(0deg); }
	40%  { transform: rotate(180deg); }
	60%  { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}
.loader-3 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 6px;
	height: 6px;
	background: #FFF;
	border-radius: 50%;
	-webkit-animation: loader-3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-3-2 {
	0%   { -webkit-transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
	50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes loader-3-2 {
	0%   { transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
	50%  { transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
	100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}

.dragon-pocket {
  display: block;
  position: absolute;
  background-image: url(../../assets/dragon-pockets.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 57%;
  padding-top: 12px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1005;
}

.link-box {
  display: block;
  position: absolute;
  background: linear-gradient(270deg, #BC9167 0%, #E8C498 47.92%, #D8B483 97.92%);
  width: 70px;
  height: 37px;
  left: 49%;
  top: 64.2%;
  padding-top: 10;
  padding-left: 8px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1006;
}

.copy-icon-dragonmail{
  display: block;
  position: absolute;
  background-image: url(../../assets/copy-icon-dragonmail.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: 59.7%;
  top: 64%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1007;
}

.dropdownContainer {
  display: flex;
  outline: none !important;
  width: 146px;
  height: 36px;
  box-sizing: border-box;
  padding-left: 10;
  padding-right: 10;
  padding-top: 10;
  padding-bottom: 10;
  background-color: #E7756E;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 7px;
  border: none;
}

.dropbtn {
  display: flex;
  outline: none !important;
  width: 146px;
  box-sizing: border-box;
  padding-left: 10;
  padding-right: 10;
  padding-top: 10;
  padding-bottom: 10;
  background-color: #E7756E;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  flex-direction: row;
  
  font-size: 12px;
  text-align: left;
}

.triangle-down {
  display: block;
  position: absolute;
	width: 0;
	height: 0;
  left: 76%;
  top: 61.7%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 12px solid #D9D9D9;
}

.dropdown {
  display: block;
  position: absolute;
  top: 57%;
  left: 8%;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 10px;
  background-color: #E7756E;
  border-radius: 7px;
  width: 146px;
  overflow: auto;
  padding-left: 0px;
  z-index: 1002;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}

.account-balance-dropdown-container {
  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
}

.account-balance-dropdown {
  display: flex;
  border: none;
  background-color: #e5645e;
  padding-left: 55px;
  padding-right: 140px;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: 0.5s;
  
  color: rgba(255, 255, 255, 0.8);
  border-radius: 40px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.account-balance-dropdown-content {
  display: none;
  position: absolute;
  left: 10px;
  background-color: #E7756E;
  border-radius: 7px;
  width: 200px;
  overflow: auto;
  padding-left: 0px;
  z-index: 1002;
}

.account-balance-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.show-balance {display: block;}

.stake-button {
  position: absolute;
  display: flex;
  background-color: #460109;
  width: 150px;
  height: 30px;
  cursor: pointer;
  bottom: 8px;
  left: 28px;
  border-radius: 25px;
  justify-content: center;
  vertical-align: middle;
}

.blackdragon-logo {
  position:relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(../../assets/blackdragon-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.neko-logo {
  position:relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(../../assets/NEKO-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.lonk-logo {
  position:relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(../../assets/LONK-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.shitzu-logo {
  position:relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(../../assets/SHITZU-logo.jpg);
  background-size: contain;
  background-repeat: no-repeat;
}

.stake-button {
  
}

@media screen and (max-width: 750px) {
  .envelope-logo {
      background: linear-gradient(87.02deg, #FFBCBC 2.21%, #F85656 49.38%, #FF0101 97.53%);
      width: 130px;
      height: 130px;
      background-image: url('../../assets/dragonmailLogo.svg');
      background-repeat: no-repeat;
      background-size:contain;
  }

  .envelope-logo-container {
      display: flex;
      width: 100px;
      padding: 0px;
      margin-top: 15;
      margin-left: 15;
  }

  .desktopAccountButtonContainer {
    display: flex;
    position: absolute;
    top: 30;
    right: 0;
    align-items: right;
    flex-direction: row;
    justify-content: center;
  }

  .account-btn-grad {
    background-color: #ff0101;
    /* box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25); */
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 0px;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 40px;
    display: inline-block;
    z-index: 1;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  
  .token-btn-grad {
    background-color: #e5645e;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
    top: 30px;
    right: 0px;
    margin: 10px;
    padding-left: 0px;
    padding-right: 70px;
    padding-bottom: 0px;
    padding-top: 0px;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 40px;
    display: block;
    position: absolute;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }

  .account-balance-dropdown-container {
    display: block;
    position: absolute;
    top: 30px;
    right: 0px;
    margin: 10px;
  }
  
  .account-balance-dropdown {
    display: flex;
    border: none;
    background-color: #e5645e;
    padding-left: 40px;
    padding-right: 110px;
    padding-bottom: 0px;
    padding-top: 0px;
    transition: 0.5s;
    
    color: rgba(255, 255, 255, 0.8);
    border-radius: 40px;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }

  .account-balance-dropdown-content {
    width: 180px;
  }

  .envelopeBackgroundContainer {

  }

  .buttonBox {
    width: 280px;
    margin: 0px auto;
    margin-bottom: 20px;
    margin-top: 40px;
    position: relative;
    border-radius: 30px;
    background: #eb3323;
  }
  
  .toggleBtn {
    padding: 10px 46px;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    text-align: center;
    color: #fff;
  
    font-family: "Inter";
    font-style: bold;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
}