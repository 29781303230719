.currencyComponent {
    background-color: #005662;
    /* width: 128px; */
    min-width: 130;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    padding: 0.2;
}

.currencyData {
    width: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Thabit', monospace;
    line-height: 18px;
}

.currencyLogo {
    padding-top: 8px;
    /* padding-bottom: 8px; */
    padding-right: 16px;
}

.currentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}