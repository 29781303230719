.mainPage {
    height: 100vh;
    background: linear-gradient(259.29deg, #FFBDBD 10.97%, #F64B4B 55.72%, #FF0000 79.04%);
    display: grid;
    overflow: scroll;
}

.container {
    display: flex;
    width: 95vw;
    height: 95vh;
}

.ellipseMainContainer {
    width: 188px;
    display: flex;
    justify-content: center;
    /* z-index: 10; */
}

.ellipseMain {
    margin-top: 46px;
    margin-right: 20px;
    width: 130px;
    height: 130px;
    border-radius: 100px;
    background-image: url('../../assets/logo.svg');
    align-self: flex-start;
}

.midContainer__headingElipse {
    background-color: #005662;
    ;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    background: rgba(196, 196, 196, 0.2);
    margin-left: 50px;
    margin-right: 30px;
    /* width: 100%; */
    border-radius: 15px;

    min-width:250px;
    max-width: 250px;

}

.chats {
    

}

.buttonWindow {
    width: 184px;
    text-align: center;
}

.button {
    width: 165px;
    height: 47px;
    background: #00838F;
    border-radius: 40px;
    position: absolute;
    bottom: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Thabit', monospace;
    font-size: 24px;
    font-weight: 700;

}

.midContainer {
    /* height: 80%;
    min-width: 300px;
    flex: 0.60;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-top: 86px; */
    height: '100%';
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
    /* width: 100%; */
    border-radius: 15px;
    margin-top: 86px;
    border: 1;
    border-color: #000000;


}

.midContainer__heading {
    background-color: #66B6C0;

    border-radius: 15px 15px 0px 0px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;

    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 90.45%;
}

.midContainer__heading__chatName {
    flex: 1;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Thabit', monospace;
    color: rgba(1, 131, 143, 1);
}

.midContainer__body {
    flex: 1;
    /* background-color: #4F7CBF; */
    /* background: rgba(172, 215, 219, 0.3); */
    background: rgba(172, 215, 219, 0.3);
    overflow-y: scroll;
    scroll-snap-type: y proximity;
    background-image: url('../../assets/logo.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 150px;
    /* frontend/src/assets/logo.svg */
}

.midContainer__body_Logo {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background-image: url('../../assets/logo.svg');
    /* background: linear-gradient(87.02deg, #005662 2.21%, #00838F 49.38%, #4FB3BF 97.53%); */
}

.midContainer__blank {
    flex: 1;
    overflow: auto;

}


.midContainer__footer {
    background: #66B6C0;
    border-radius: 0px 0px 15px 15px;
}

.midContainer__footerInput {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    
}

.midContainer__footerInput>input {
    outline-width: 0;
    border: none;

    padding: 20px;
    margin-top: 11px;
    margin-bottom :11px;
  
    width: 100%;
    height: 30px;
    text-align: end;

}

.rightContainerWrapper {
    /* height: 80%;
    flex: 0.20;
    margin-top: 86px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; */
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    margin-left: 30px;
    /* width: 100%; */
    border-radius: 15px;
    margin-top: 86px;
    /* background: #FFFFFF;
    opacity:0.2; */
    background-color: rgba(255, 255, 255, 0.2);
    min-width: 200px;
    max-width: 200px;
}


.rightContainer {
    background-color: #4FB3BF;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 15px;
    justify-content: space-between;
    margin-right: 36px;

}



.rightContainer__configureMsg {
    margin-top: 11px;
}

.rightContainer__configureMsg__heading {
    font-family: 'Thabit', monospace;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin: 11px 10%;
}


.rightContainer__configureMsg__subheading {
    font-family: 'Thabit', monospace;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin: 11px 10%;
}

.rightContainer__readReward__heading {
    font-family: 'Thabit', monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
    padding: 5px;
}

.rightContainer__readReward__input>input {

    background: #ACD7DB;
    border-radius: 15px;
    /* transform: matrix(1, 0, 0, -1, 0, 0); */

    border-color: #ffffff00;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;
    padding: 5px;

    color: #000000;
}


.rightContainer__readReward__input>::placeholder {

    opacity: 1;
    /* Firefox */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;
    padding: 5px;
    color: #70BAC2;

}

.rightContainer__unlockReward__heading {
    font-family: 'Thabit', monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.rightContainer__unlockReward__input>input {
    outline-width: 0;
    border: none;
    padding: 5px 20px;
    padding-bottom: 3px;
    /* margin: 5px 10px; */
    border-radius: 50px;
    background-color: #FFFFFF;
    width: 78%;
    height: 30px;
    text-align: center;
    font-family: 'Thabit', monospace;
    font-weight: 200;
    font-size: 18px;
    color: #000000;
}

.rightContainer__send {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}

.rightContainer__send__button {
    background: #00838F;
    border-radius: 40px;
    font-style: normal;
    padding: 5px;
    font-weight: bold;
    font-size: 18px;
    /* text-align: center; */
    color: #FFFCFC;
    width: 80%;
    height: 2em;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Thabit', monospace;
    cursor: pointer;

}

.ModalWithdraw {

    position: absolute;
    top: 20%;
    left: 38%;
    background: #005662;
    border-radius: 30px;
    width: 383px;
    Height: 385px;
    outline-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px;
}

.modalWithdraw__details {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.modalWithdraw__details__info {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    font-family: 'Thabit', monospace;
    color: #FFFFFF;
    margin-left: 20px;
}

.ModalWithdraw__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: 'Thabit', monospace;


}

.ModalWithdraw__buttons__deposit {
    background: #4FB3BF;
    border-radius: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 29px;
    width: 141px;
    height: 55px;
    cursor: pointer;
}

.ModalWithdraw__buttons__withdraw {
    background: #4FB3BF;
    border-radius: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 29px;
    width: 141px;
    height: 55px;
    cursor: pointer;
}

.headingModalWithdraw {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 63px;
    text-align: center;
    font-family: 'Thabit', monospace;
    color: #FFFFFF;
    margin-top: 20px;
}

.linkModal {
    background: #005662;
    outline-style: none;
    backdrop-filter: blur(4px);
    border-radius: 15px;
    width: 788px;
    height: 112px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 35%;
    left: 25%;
    padding: 0px 20px;

}

.linkModal__link {
    flex: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 45px;
    text-align: center;
    font-family: 'Thabit', monospace;
    color: #FFFFFF;
    max-width: 650px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.linkModal__copyLink {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.linkModal__copyLink__copy {
    font-family: 'Thabit', monospace;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.rightContainer__readReward {
    padding-left: 2vw;
    padding-right: 2vw;
}

.rightContainer__unlockReward__input {
    padding-left: 1vw;
    padding-right: 1vw;
}