


.flex-container {
    min-height: 100vh;
    padding: 10;
    align-items: center;
    overflow-y: scroll;
    background: linear-gradient(259.29deg, #66B6C0 10.97%, #00838F 55.72%, #005662 79.04%);
}

.flex-sub-container{
    margin: 3;
}

.chat-box-heading{
    background-color: #66B6C0;
    border-radius: 15px 15px 0px 0px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    height: 100;
    align-items: center;
    
}

.contact-box-heading{
    /* background: rgba(196, 196, 196, 0.2); */
    /* border-radius: 15px 15px 0px 0px; */
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 6px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    
}

.contact-box-footer{
   
    /* border-radius: 0px 0px 15px 15px; */
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 6px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    
}