
.cs-button--send {
    color: #005662;
    background-color: transparent;
}
.cs-button:disabled {
  opacity: .6;
}

.cs-button--adduser, .cs-button--arrow, .cs-button--ellipsis, .cs-button--info, .cs-button--star, .cs-button--videocall, .cs-button--voicecall, .cs-button--send, .cs-button--attachment{
  color: #005662;
}


.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content{
  background-color:#FFFFFF;
  opacity: .5;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content{
  background-color:#FFFFFF;
  opacity: .5;
}

.cs-message-input__content-editor{
  background-color:#FFFFFF;
  
}
.cs-message-input__content-editor-wrapper{
  background-color:#FFFFFF;
  border-radius:50px;
}
